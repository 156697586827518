import img1 from '../Images/swrg.png';
import img2 from '../Images/portfolio.png';
import img3 from '../Images/rnf.png';

const portfolios = [
    {
        id: 1,
        category: 'ReactJs',
        image: img2,
        link1: 'https://www.taiammumuday.xyz',
        title: 'Simple Portfolio Website',
        text: 'Language: HTML, CSS, ReactJS',
        description: 'A simple portfolio website to introduce myself and my skillsets.',
    },
    {
        id: 2,
        category: 'Laravel',
        image: img1,
        link1: 'https://swargasayagroup.com',
        title: 'Company Profile Website With Admin Panel',
        text: 'Language: HTML, CSS, Bootstrap, Laravel',
        description: 'Landing page website, fully controllable through a strong admin panel. Easy to use and secured.',
    },
    {
        id: 3,
        category: 'Laravel',
        image: img3,
        link1: 'https://rnfmobile.com',
        title: 'Tech Media Website With Admin Panel',
        text: 'Language: HTML, CSS, Bootstrap, Laravel, JavaScript',
        description: 'Tech media website, fully controllable through a strong admin panel. Easy to use and secured.',
    }
]

export default portfolios;
