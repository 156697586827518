import React from 'react'
import styled from 'styled-components';
import AbtImg from '../Images/resume.jpg';
import PButton from '../Components/PButton';

function AboutImage() {
    return (
        <ImageStyle>
            <div className="left-content">
                <img src={AbtImg} alt="Taiammum Uday" />
            </div>
            <div className="right-content">
                <div className="stitle">
                    <h4>I'm <span>Taiammum Uday</span></h4>
                </div>
                <p className="biography">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis quia eum, nihil, fuga magni iste alias obcaecati reiciendis quam facilis magnam, nulla qui unde veniam! Aliquid, a deserunt! Eius, laboriosam.
                </p>
                <div className="abt-info">
                    <div className="title-info">
                        <p>Full Name </p>
                        <p>Age </p>
                        <p>Nationality </p>
                        <p>Language </p>
                        <p>Location </p>
                        <p>Expertise </p>
                    </div>
                    <div className="info">
                        <p>: Taiammum Uday</p>
                        <p>: 23 Years </p>
                        <p>: Bangladeshi </p>
                        <p>: Bengali/English </p>
                        <p>: Dhaka, Bangladesh </p>
                        <p>: Web Design/Developement, Database Management </p>
                    </div>
                </div>
                <PButton title={'Check Out Skillsets'} onclick={"location.href='/skills'"}/>
            </div>
        </ImageStyle>
    )
}

const ImageStyle = styled.div`
    margin-top: 5rem;
    display: flex;
    @media screen and (max-width:1000px){
        flex-direction: column;
        .left-content{
            margin-bottom: 2rem;
        }
    }
    .left-content{
        width: 100%;
        img{
            width: 95%;
            object-fit: cover;
            z-index: -1;
        }
    }
    .right-content{
        width: 100%;
        h4{
            font-size: 2rem;
            color: var(--white-color);
            span{
                font-size: 2rem;
                color: var(--primary-color);
            }
        }
        .biography{
            padding: 1rem 0;
        }
        .abt-info{
            display: flex;
            padding-bottom: 1.4rem;
            .title-info{
                padding-right: 3rem;
                p{
                    font-weight: 600;
                }
            }
            .title-info, .info{
                p{
                    padding: .3rem 0;
                }
            }
        }
    }
`;

export default AboutImage