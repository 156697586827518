import React from 'react'
import styled from 'styled-components';
import { InnerLay, MainLay } from '../Styles__/LayoutM';
import Title from '../Components/Title';
import SmallTitle from '../Components/SmallTitle';
import SchoolIcon from '@material-ui/icons/School';
import ResumeItem from '../Components/ResumeItem';


function Education() {
    const school = <SchoolIcon />
    return (
        <MainLay>
            <ResumeStyled>
                <Title title={'Resume'} />
                <InnerLay>
                    <div className="small-title">
                        <SmallTitle icon={school} title={'Educational Qualifications'} />
                    </div>
                    <div className="resume-content ">
                        <ResumeItem
                            year={'2018 - 2022'}
                            title={'BSc in Computer Science & Engineering'}
                            subTitle={'East West University'}
                            text={'I\'m a former member of East West University Computer Programming Club. Had keen interest on robotics but it turned out that I\'m much interested and attracted towards programming than robotics. So, took SOFTWARE ENGINEERING major.'}
                        />
                        <ResumeItem
                            year={'2015 - 2017'}
                            title={'Higher Secondary School Certificate'}
                            subTitle={'Dhaka Imperial College'}
                            text={'Science Group, HSC batch of 2017. I\'m a former member of Dhaka Imperial College Science Club. Once won the 3rd position of Web Designing Contest of an Science Fair. I was a very quick learner. My keen interest on back-end language, such as PHP started during this time.'}
                        />
                        <ResumeItem
                            year={'2013 - 2015'}
                            title={'Secondary School Certificate'}
                            subTitle={'Faizur Rahman Ideal Intitute'}
                            text={'Science Group, SSC batch of 2015. My hobby was to learn and implement newer things. I started to wondering how a website is built, what kind of command it took to create facebook. So, started to learn HTML & CSS. I can still how I felt after seeing the Hello World! in Mozilla.'}
                        />
                    </div>
                    </InnerLay>
            </ResumeStyled>
        </MainLay>
    );
}

const ResumeStyled = styled.section`
    .small-title{
        padding-bottom: 3rem;
    }
    .u-small-title-margin{
        margin-top: 4rem;
    }

    .resume-content{
        border-left: 2px solid var(--border-color);
    }
`;

export default Education;
