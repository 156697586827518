import { useState } from "react";
import { useEffect } from "react";
import Sidebar from "./Components/Sidebar";
import styled from 'styled-components';
import { Route, Switch as Switching} from "react-router";
import Homepage from "./Pages/Homepage";
import About from './Pages/AboutMe';
import Education from './Pages/Education';
import Skills from './Pages/Skills';
import Portfolio from './Pages/Portfolio';
import Contact from './Pages/Contact';
import { IconButton } from "@material-ui/core";
import Brightness4Icon from '@material-ui/icons/Brightness4';
import MenuIcon from '@material-ui/icons/Menu';
import Switch from '@material-ui/core/Switch'



function App() {
  const [theme, setTheme] = useState('dark-theme');
  const [checked, setChecked] = useState(false);
  const [navToggle, setNavToggle] = useState(false);

  useEffect(()=>{
    document.documentElement.className = theme;
  }, [theme]);

  const themeToggler = () =>{
    if(theme === 'light-theme'){
      setTheme('dark-theme');
      setChecked(false)
    }else{
      setTheme('light-theme');
      setChecked(true)
    }
  }

  return (
    <div className="App">
      <Sidebar navToggle={navToggle} />
      <div className="theme">
          <div className="light-dark-mode">
              <div className="left-content">
                <Brightness4Icon />
              </div>
              <div className="right-content">
                <Switch
                  value=""
                  checked={checked}
                  inputProps={{ 'aria-label': '' }}
                  size="medium"
                  onClick={themeToggler}
                  
                />
              </div>
            </div>
        </div>

        <div className="ham-burger-menu">
          <IconButton onClick={() => setNavToggle(!navToggle)}>
              <MenuIcon />
          </IconButton>
        </div>
      <MainCstyle onClick={() => setNavToggle(false)}>
        <div className="lines">
          <div className="lines-1"></div>
          <div className="lines-2"></div>
          <div className="lines-3"></div>
          <div className="lines-4"></div>
        </div>
          <Switching>
          <Route path="/" exact>
              <Homepage />
            </Route>
            <Route path="/About" exact>
              <About />
            </Route>
            <Route path="/Education" exact>
              <Education />
            </Route>
            <Route path="/Skills" exact>
              <Skills />
            </Route>
            <Route path="/Portfolio" exact>
              <Portfolio />
            </Route>
            <Route path="/Contact" exact>
              <Contact/>
            </Route>
          </Switching>
      </MainCstyle>
    </div>
  );
}

const MainCstyle = styled.main`
position: relative;
  margin-left: 16.3rem;
  min-height: 100vh;
  @media screen and (max-width:1200px){
    margin-left: 0;
  }
  .lines{
    position: absolute;
    min-height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    opacity: 0.4;
    z-index: -1;
    .line-1, .line-2, .line-3, .line-4{
      width: 1px;
      min-height: 100vh;
      background-color: var(--border-color);
    }
  }
`;

export default App;
