import React from 'react'
import styled from 'styled-components';
import { InnerLay, MainLay } from '../Styles__/LayoutM';
import Title from '../Components/Title';
import ProgressBar from '../Components/ProgressBar';
import SubTitle from '../Components/SubTitle';

function Skills() {
    return (
        <MainLay>
            <SkillsStyle>
                <Title title={'Skills'} />
                <InnerLay>
                    <p className="biography">
                        During all this time, I learned many programming languages alongside various tool's usage to increase my effienciency and understand various concepts. Here are the skillset I have achieved:
                    </p>
                    <SubTitle title={'Front-End'} />
                    <div className="skillsx">
                        <ProgressBar
                            title={'HTML5'}
                            width={'70%'}
                            text={'70%'}
                        />
                        <ProgressBar
                            title={'CSS3'}
                            width={'90%'}
                            text={'90%'}
                        />
                        <ProgressBar
                            title={'JAVASCRIPT'}
                            width={'85%'}
                            text={'85%'}
                        />
                    </div>
                    <br/>
                    <SubTitle title={'Back-End'} />
                    <div className="skillsx">
                        <ProgressBar
                            title={'PHP'}
                            width={'75%'}
                            text={'75%'}
                        />
                        <ProgressBar
                            title={'C/C++'}
                            width={'80%'}
                            text={'80%'}
                        />
                        <ProgressBar
                            title={'Python'}
                            width={'62%'}
                            text={'62%'}
                        />
                        <ProgressBar
                            title={'Java'}
                            width={'52%'}
                            text={'52%'}
                        />
                    </div>
                    <br/>
                    <SubTitle title={'Frameworks/Libraries'} />
                    <div className="skillsx">
                        <ProgressBar
                            title={'ReactJS'}
                            width={'70%'}
                            text={'70%'}
                        />
                        <ProgressBar
                            title={'Laravel'}
                            width={'75%'}
                            text={'75%'}
                        />

                        <ProgressBar
                            title={'BootStrap'}
                            width={'75%'}
                            text={'75%'}
                        />

                        <ProgressBar
                            title={'jQuery'}
                            width={'65%'}
                            text={'65%'}
                        />
                        <ProgressBar
                            title={'AngularJs'}
                            width={'52%'}
                            text={'52%'}
                        />
                    </div>
                    <br/>
                    <SubTitle title={'Systems/Tools'} />
                    <div className="skillsx">
                        <ProgressBar
                            title={'MySQL'}
                            width={'82%'}
                            text={'82%'}
                        />
                        <ProgressBar
                            title={'Oracle DBMS'}
                            width={'70%'}
                            text={'70%'}
                        />
                        <ProgressBar
                            title={'Visual Studio Code'}
                            width={'73%'}
                            text={'73%'}
                        />
                        <ProgressBar
                            title={'CodeBlocks'}
                            width={'65%'}
                            text={'65%'}
                        />
                        <ProgressBar
                            title={'GitHub'}
                            width={'60%'}
                            text={'60%'}
                        />
                        <ProgressBar
                            title={'Codepen'}
                            width={'60%'}
                            text={'60%'}
                        />
                    </div>
                </InnerLay>
            </SkillsStyle>
        </MainLay>
    );
}

const SkillsStyle = styled.section`
.skillsx{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 2rem;
        grid-column-gap: 3rem;
        @media screen and (max-width: 700px){
            grid-template-columns: repeat(1, 1fr);
        }
    }
    .biography{
            padding-top: 0;
            padding-bottom: 1rem;
        }
`;

export default Skills;
