import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import GlobalStyles from './Styles__/globalStyles';
import { BrowserRouter } from "react-router-dom";

ReactDOM.render(
  <>
    <GlobalStyles />
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </>
  , document.getElementById('root')
);
