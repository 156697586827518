import styled from 'styled-components';
import Particle from '../Components/Particles';
import Typed from 'react-typed';
import FacebookIcon from '@material-ui/icons/Facebook';
import GitHubIcon from '@material-ui/icons/GitHub';
import EmailIcon from '@material-ui/icons/Email';
import Delayed from '../Components/Delayed.js';

function Homepage() {
    return (
        <HomeStyle>
            <div className="p-part">
                <Particle />
            </div>
            <div class="typer">
            <Typed
                    strings={['<h1>Hello!</h1>']}
                    typeSpeed={120}

                />
                <br /><br />

                <Delayed waitBeforeShow={1500}>
                    <Typed
                        strings={["<h1>I'm <span>Taiammum Uday!</span></h1>"]}
                        typeSpeed={120}
                    />
                    <br /><br />
                </Delayed>

                <Delayed waitBeforeShow={5000}>
                    <Typed
                        strings={["<h2>Thanks for paying me a visit!<h2>"]}
                        typeSpeed={120}
                    />
                    <br /><br />
                </Delayed>
                <div className="icons">
                    <Delayed waitBeforeShow={10500}>
                        <a href="http://www.facebook.com/cjuday88" className="icon i-facebook">
                            <FacebookIcon />
                        </a>
                        <a href="http://www.github.com/cjuday" className="icon i-github">
                            <GitHubIcon />
                        </a>
                        <a href="mailto:cjuday88@gmail.com" className="icon i-email">
                            <EmailIcon />
                        </a>
                   </Delayed>
                </div>
            </div>
        </HomeStyle>
    )
}

const HomeStyle = styled.header`
    width: 100%;
    height: 100vh;
    position: relative;

    .typer{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        width: 80%;
    }

    .icons{
        display: flex;
        justify-content: center;
        .icon{
            border: 2px solid var(--border-color);
            display: inline-block;
            align-items: center;
            border-radius: 50%;
            justify-content: center;
            margin-top: 1rem;
            transition: all 0.6s ease-in-out;
            &:not(:last-child)
            {
                margin-right: 1rem;
            }
            &:hover{
                border: 2px solid var(--primary-color);
                color: var(--primary-color);
                cursor: pointer;
            }
            svg{
                margin: .5rem;
            }
        }
        .i-email{
                    &:hover{
                        border: 2px solid red;
                        color: red;
                }
            }

            .i-github{
                    &:hover{
                        border: 2px solid #5F4687;
                        color: #5F4687;
                }
            }
    }

`;
export default Homepage;
