import styled from 'styled-components';
import {MainLay} from '../Styles__/LayoutM';
import Title from '../Components/Title';
import AboutImage from '../Components/AboutImage';
import Services from '../Components/Services';

function About() {
    return (
        <MainLay>
            <AboutStyled>
            <Title title={'About Me'}/>
            <AboutImage/>
            <Services/>
            </AboutStyled>
        </MainLay>
    )
}

const AboutStyled = styled.section`

`;
export default About;
