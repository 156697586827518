import React from 'react'
import styled from 'styled-components';
import Title from '../Components/Title';
import { InnerLay } from '../Styles__/LayoutM';
import SrvCard from '../Components/SrvCard';
import wdesign from '../Images/wdesign.svg';
import db from '../Images/db.png';
import develop from '../Images/wdev.png';

function Services() {
    return (
        <InnerLay>
            <ServiceStyle>

                <Title title={'Services'} />
                    <div className="services">
                        <SrvCard image={wdesign} title={'Web Design'} para={'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis consequat ante at velit elementum, congue volutpat nisi semper. Duis ultrices augue nec purus faucibus fringilla. Donec sollicitudin sapien odio, lobortis mollis libero congue eget. Cras fringilla ipsum in nisl pretium, sed vehicula dolor efficitur. Etiam auctor placerat augue in efficitur.'}/>
                        
                        
                        <SrvCard image={develop} title={'Web Developer'} para={'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis consequat ante at velit elementum, congue volutpat nisi semper. Duis ultrices augue nec purus faucibus fringilla. Donec sollicitudin sapien odio, lobortis mollis libero congue eget. Cras fringilla ipsum in nisl pretium, sed vehicula dolor efficitur. Etiam auctor placerat augue in efficitur.'}/>
                        
                        <SrvCard image={db} title={'Database Expert'} para={'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis consequat ante at velit elementum, congue volutpat nisi semper. Duis ultrices augue nec purus faucibus fringilla. Donec sollicitudin sapien odio, lobortis mollis libero congue eget. Cras fringilla ipsum in nisl pretium, sed vehicula dolor efficitur. Etiam auctor placerat augue in efficitur.'}/>
                </div>
            </ServiceStyle>
        </InnerLay>
    )
}

const ServiceStyle = styled.div`
.services{
        margin-top: 5rem;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1.5rem;
        @media screen and (max-width:1000px){
            flex-direction: column;
        }
        @media screen and (max-width:950px){
            grid-template-columns: repeat(2, 1fr);
        }
        @media screen and (max-width:650px){
            grid-template-columns: repeat(1, 1fr);
        }
       
    }
`;

export default Services;
