import React from 'react'
import styled from 'styled-components';

function SubTitle({title}) {
    return (
        <SubTitleStyle>
            <h5>{title}</h5>
        </SubTitleStyle>
    )
}

const SubTitleStyle=styled.div`
    position: relative;
    padding-bottom: 1rem;
    padding-top: 1rem;
    h5{
        color: var(--white-color);
        font-size: 2rem;
        font-weight: 600;
        text-transform: uppercase;
        position: relative;
        padding-bottom: .7rem;
        @media screen and (max-width: 496px){
            font-size: 1rem;
        }
        @media screen and (max-width: 370px){
            font-size: 0.8rem;
        }
        &::before{
            content: "";
            position: absolute;
            bottom: 0;
            width: 7.4rem;
            height: .33rem;
            background-color: var(--background-light-color-2);
            border-radius: 15px;
            left: 0;
        }
        &::after{
            content: "";
            position: absolute;
            bottom: 0;
            width: 3.5rem;
            height: .33rem;
            background-color: var(--primary-color);
            border-radius: 15px;
            left: 0;
        }
    }
`;

export default SubTitle;
